import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";
import BlogCardsListing from "../components/BlogCardsListing/BlogCardsListing";
import CategoryList from "../components/CategoryList/CategoryList";
import Pagination from "../components/Pagination/Pagination";

const SeoData = {
  Title: "Blog | Sajdah - The World's First Smart Educational Prayer Rug",
  Keywords: [`Blog`, `Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
  Description: "Blog for Sajdah, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
  Image: "https://getsajdah.com/sajdah_og_image_thumb.jpg",
  url: "https://getsajdah.com/blog",
  titleTemplate: "Blog | Sajdah - The World's First Smart Educational Prayer Rug"
};

const BlogListing = props => {

  const cardsListing = props.data.allPrismicBlogPost.edges
  const categories = props.pageContext.categoryList


  return (
    <Layout>
      <SEO
        description={SeoData.Description}
        keywords={SeoData.Keywords}
        title={SeoData.Title}
        image={SeoData.Image}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
    />
      <HeaderInfoPage title="Blog" />
      <CategoryList 
        listingType="blog"
        categories={categories}
      />
      <BlogCardsListing blogCards={cardsListing} />
      <Pagination 
        numPages={props.pageContext.numPages}
        currentPage={props.pageContext.currentPage}
      />
    </Layout>
  )
}


export default BlogListing;



export const BlogListingPageQuery = graphql`
  query BlogListingQuery($skip: Int!, $limit: Int!) {
    allPrismicBlogPost(skip: $skip, limit: $limit,sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          uid
          tags
          data {
            date
            featured_image {
              url
            }
            post_title {
              text
            }
            category {
              text
            }
            body {
              primary {
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
` 